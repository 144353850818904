<script lang="ts" context="module">
  import { locale } from "svelte-i18n";
  import { init } from "./i18n";
  await init();
</script>

<script lang="ts">
  void init();
  export let lang: string | undefined;

  if (
    (typeof document != "object" || import.meta.env.DEV) &&
    !import.meta.env.SSR
  )
    $locale = lang;
</script>
